import * as React from 'react'
import Layout from '../layout'
import EventCode from '../pagesComponents/event-code'

const EventCodePage = ({ location }) => {
	const params = new URLSearchParams(location.search)
	const eventId = params.get('eventId')?.toString() || null

	return (
		<Layout theme="light" title="Account Created">
			<EventCode eventId={eventId} />
		</Layout>
	)
}

export default EventCodePage
