import React, { useEffect, useState } from 'react'
import BasePage from '../components/basePage'
import { Button, Text, Container, useToast } from '@chakra-ui/react'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import { setTempGroup, getEventId } from '../helpers/database'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { errorToastOptions } from '../helpers'

import Input from '../components/input'

import eventTracking from '../services/eventTracking'
import { createGroup } from '../services/group'
// import { getTeamByRfid } from '../../services/team'

const EventCode = ({ eventId }) => {
	const queryClient = useQueryClient()
	const toast = useToast()
	const [isFetching, setIsFetching] = useState(false)

	const resolver = yupResolver(
		object({
			eventCode: string().trim().required().min(2)
		}).required()
	)
	const {
		watch,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver,
		mode: 'onBlur',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false,
		defaultValues: {
			eventCode: getEventId()
		}
	})

	const watchEventCode = watch('eventCode')
	const isValid = !!watchEventCode

	const createGroupMutation = useMutation(createGroup, {
		onSuccess: async (data) => {
			eventTracking('group_created', {
				group_id: data?.id
			})

			setTempGroup(data)

			await queryClient.invalidateQueries(['groups'], {
				exact: true
			})

			return navigate(`/rfid?group=${data?.id}`)
		},
		onError: (error) => {
			setIsFetching(false)
			console.log('[CREATE_GROUP]', error)

			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	useEffect(() => {
		if (eventId && eventId.length > 0) {
			setIsFetching(true)
			createGroupMutation.mutate({
				eventId: eventId
			})
		}
	}, [eventId])

	const onSubmit = async (formData) => {
		const { eventCode } = formData

		if (eventCode !== 'undefined' && eventCode && eventCode.length > 0) {
			// See if there is an existing group
			setIsFetching(true)
			createGroupMutation.mutate({
				eventId: formData?.eventCode
			})
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<BasePage
				theme="dark"
				access={1}
				parent="login"
				noHeader
				// additionalTopPadding
				fullWidthActionBtns
				largeTitle={
					<Container pb={10}>
						<Text color="white" as="h1" textAlign="center">
							WELCOME TO
						</Text>
						<StaticImage
							width={380}
							alt="Logo"
							style={{ marginBottom: 80, objectFit: 'contain' }}
							src="../../images/logo-white.png"
						/>
						<br />
						Enter your
						<br />
						Event Code
					</Container>
				}
				primaryBtn={
					<Container maxW="md" centerContent>
						<Button
							style={{ width: '100%' }}
							variant="primary"
							isLoading={isFetching}
							disabled={!isValid}
							type="submit"
						>
							Continue
						</Button>
					</Container>
				}
			>
				<Container maxW="md">
					<Input
						name="eventCode"
						type="number"
						variant="filled"
						placeholder="Event code"
						mt="20px"
						register={register}
						errors={errors}
					/>
					<Text
						variant="m"
						color="white"
						textAlign="center"
						mt="10px"
					>
						Tip: Our check-in team will provide your group event
						code.
					</Text>
				</Container>
			</BasePage>
		</form>
	)
}

export default EventCode
